import './assets/css/App.css';
import './i18n';
import Contact from './components/Contact';
import Header from './components/Header';
import Offering from './components/Offering';
import PriceCard from './components/PriceCard';
import TitleImage from './components/TitleImage';
import Footer from './components/Footer';
import { FeatureProvider } from './assets/context/FeatureContext';

function App() {
  return (
    <div className="myWeddingPage">
      <header>
        <Header />
      </header>
      <div className='body'>
        <TitleImage />
        <Offering />

        <FeatureProvider>
          <PriceCard />
          <Contact />
        </FeatureProvider>
        
        <Footer />
      </div>
    </div>
  );
}

export default App;
