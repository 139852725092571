import '../assets/css/Header.css';

import { AppBar, Avatar, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";

import logo from '../assets/images/logo.png';
import { PublicOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export default function Header() {
    const { t } = useTranslation();
    const [anchorElement, setAnchorElement] = useState(null);
    const open = Boolean(anchorElement);

    const handleClick = (event) => {
        setAnchorElement(event.currentTarget);
    }

    const handleClose = (language) => {
        setAnchorElement(null);

        i18next.changeLanguage(language);
    }


    return (
        <AppBar position="sticky" sx={{ backgroundColor: '#fff' }}>
            <Container>
                <Toolbar disableGutters>
                    <Avatar className='avatar' src={logo} alt="Logo" />
                    <Typography variant="h5" noWrap component="div" sx={{ fontFamily: 'Dancing Script', flexGrow: 1}}>
                        OurWeddingPage
                    </Typography>
                    <IconButton onClick={handleClick}>
                        <PublicOutlined />
                    </IconButton>
                    <Menu id='language-menu' anchorEl={anchorElement} open={open} onClose={() => handleClose()}
                        MenuListProps={{'aria-labelledby': 'language-button'}}>
                            <MenuItem onClick={() => handleClose('de')}>{t('translation.de')}</MenuItem>
                            <MenuItem onClick={() => handleClose('en')}>{t('translation.en')}</MenuItem>
                    </Menu>
                </Toolbar>
            </Container>
        </AppBar>
    )
}