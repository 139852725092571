import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const userLanguage = navigator.language || navigator.userLanguage;
let defaultLanguage = 'en';

if (userLanguage.startsWith('de')) {
    defaultLanguage = 'de';
}

i18n
    .use(I18NextHttpBackend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        lng: defaultLanguage,
        fallbackLng: 'en',
        detection: {
            order: ['queryString', 'cookie', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;