import { Typography } from '@mui/material';
import '../assets/css/Offering.css';
import uniqueToYou from '../assets/images/offering/unique-style.png';
import weddingInfo from '../assets/images/offering/wedding-info.png';
import rsvp from '../assets/images/offering/rsvp.png';
import present from '../assets/images/offering/present.png';
import OfferingCard from './OfferingCard';
import { useTranslation } from 'react-i18next';

export default function Offering() {
    const { t } = useTranslation();
    return (
        <div className='offering'>
            <Typography sx={{marginTop: '1rem', marginBottom: '1rem', fontWeight: 'bold'}} variant='h5'>{t('offering.title')}</Typography>
            <div className='offering-cards'>
                <OfferingCard 
                    title={t('offering.uniqueTitle')}
                    content={t('offering.uniqueContent')}
                    image={uniqueToYou} />
                <OfferingCard 
                    title={t('offering.infoTitle')}
                    content={t('offering.infoContent')}
                    image={weddingInfo} />
                <OfferingCard 
                     title={t('offering.rsvpTitle')}
                     content={t('offering.rsvpContent')}
                    image={rsvp} />
                <OfferingCard 
                     title={t('offering.giftTitle')}
                     content={t('offering.giftContent')}
                    image={present} />
            </div>
        </div>
    )
}