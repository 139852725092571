import { useTranslation } from 'react-i18next';
import '../assets/css/TitleImage.css';

export default function TitleImage() {
    const { t } = useTranslation();

    return (
        <div className='title-image-wrapper'>
            <div className='title-text-wrapper'>
                <h1>{t('title')}</h1>
                <h3>{t('subtitle')}</h3>
            </div>
        </div>
    )
}