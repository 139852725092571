import { createContext, useContext, useState } from "react";

const FeatureContext = createContext();

export function useFeatureContext() {
    return useContext(FeatureContext);
}

export const FeatureProvider = ({ children }) => {
    const [features, setFeatures] = useState({
        rsvp: false,
        present: false,
        loveStory: false,
    });

    return (
        <FeatureContext.Provider value={{ features, setFeatures }}>
            {children}
        </FeatureContext.Provider>
    );
}