import '../assets/css/OfferingCard.css';

import { Typography } from '@mui/material';

export default function OfferingCard({title, content, image}) {
    return (
        <div className='offeringCard'>
            <img src={image} alt={{title}} />
            <Typography className='title'>{title}</Typography>
            <Typography className='content'>{content}</Typography>
        </div>
    )
}