import React from 'react';

const footerStyle = {
  backgroundColor: "#fff",
  fontSize: "0.9rem",
  textAlign: "center",
  padding: "10px",
  left: "0",
  bottom: "0",
  width: "100%",
};

const Footer = () => {
  return (
    <footer style={footerStyle}>
      © {new Date().getFullYear()} OurWeddingPage
    </footer>
  );
};

export default Footer;