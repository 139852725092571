import '../assets/css/Contact.css';
import emailjs from 'emailjs-com';
import React, { useEffect, useState } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Card, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFeatureContext } from '../assets/context/FeatureContext';

export default function Contact() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ name: '', email: '', message: '', honeypot: '', rsvp: false, present: false, loveStory: false});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [infoText, setInfoText] = useState(t('contact.info'));
    const [infoStatus, setInfoStatus] = useState('');

    const { features } = useFeatureContext();

    useEffect(() => {
        setFormData(prev => ({...prev, rsvp: features.rsvp, present: features.present, loveStory: features.loveStory }))
    }, [features])

    const validateForm = () => {
        const tempErrors = {};
        tempErrors.name = formData.name ? '' : t('contact.validateName');
        tempErrors.email = formData.email && /\S+@\S+\.\S+/.test(formData.email) ? '' : t('contact.validateEmail');
        tempErrors.message = formData.message ? '' : t('contact.validateMessage');
        setErrors(tempErrors);

        return Object.values(tempErrors).every(x => x === "");
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.honeypot) return;


        
        console.log(formData, features);

        if (validateForm()) {

            setIsLoading(true);
                               
            try {
                await emailjs.send('service_k18hh1r', 'template_88uarti', formData, 'seTrVyaalQ6DEgCEs');
                setFormData({ name: '', email: '', message: '', honeypot: '' });
                setErrors({});
                setInfoText(t('contact.success'));
                setInfoStatus('success');
            } catch (error) {
                console.error('FAILED:', error);
                setInfoText(t('contact.error'));
                setInfoStatus('error');
            }
            setIsLoading(false);
        }
    };

    return (
        <div className='contact'>
            <Typography className='title'>{t('contact.title')}</Typography>
            <Card variant='outlined' className='contactCard'>
                <Box component="form" onSubmit={handleSubmit} noValidate className='contactForm'>
                    <div className={`infoText ${infoStatus}`}>
                        <InfoRoundedIcon />
                        <Typography variant='p' dangerouslySetInnerHTML={{ __html: infoText }} />
                    </div>
                    <div className='shortFields'>
                        <TextField margin='normal' required id='name' label={t('contact.name')} name='name'
                            value={formData.name} onChange={handleChange} disabled={isLoading} error={!!errors.name} helperText={errors.name} />
                        <TextField margin='normal' required id='email' label={t('contact.email')} name='email'
                            value={formData.email} onChange={handleChange} disabled={isLoading} error={!!errors.email} helperText={errors.email} />
                    </div>
                    <TextField margin='normal' required name='message' label={t('contact.message')} id='message'
                        multiline rows={4} value={formData.message} onChange={handleChange} disabled={isLoading}
                        error={!!errors.message} helperText={errors.message} />
                    <TextField type='text' name='honeypot' value={formData.honeypot} onChange={handleChange} style={{ display: 'none' }} />
                    <Button type='submit' variant='contained' className='submitButton' disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24} /> : <SendRoundedIcon />}
                    </Button>
                </Box>
            </Card>
        </div>
    );
}