import { createTheme } from "@mui/material";

export const customTheme = createTheme({
    typography: {
        allVariants: {
            color: 'black',
            fontFamily: 'Crimson Text, serif'
        }
    },
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&:not(.Mui-disabled).Mui-checked': {
                        color: '#d4af37',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label.Mui-focused': {
                        color: 'black'
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: 'rgb(253, 225, 189)'
                        },
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
                containedPrimary: {
                    backgroundColor: 'rgb(253, 225, 189)',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'rgb(253, 225, 189)',
                    },
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: 'black',
                }
            }
        }
    },
})