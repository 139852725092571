import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import '../assets/css/PriceCard.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureContext } from '../assets/context/FeatureContext';

export default function PriceCard() {
    const { t } = useTranslation();

    const { features, setFeatures } = useFeatureContext();
    const [price, setPrice] = useState(100);


    useEffect(() => {
        const selectedFeatures = Object.values(features).filter(value => value).length;

        const prices = { 0: 100, 1: 150, 2: 300, 3: 350 };
        setPrice(prices[selectedFeatures] || 100);

    }, [features])

    const handleFeatureChange = (feature) => (event) => {
        setFeatures(prevFeatures => ({
            ...prevFeatures,
            [feature]: event.target.checked,
        }));
    };

    return (
        <div className='priceCard'>
            <Typography className='title'>{t('price.title')}</Typography>
            <div className='priceCardContent'>
                <div className='checkBoxes'>
                    <FormControlLabel label={t('price.domain')} control={
                        <Checkbox defaultChecked disabled />
                    }/>                
                    <FormControlLabel label={t('price.language')} control={
                        <Checkbox defaultChecked disabled />
                    }/>
                    <FormControlLabel label={t('price.rsvp')} control={
                        <Checkbox checked={features.rsvp} onChange={handleFeatureChange('rsvp')} />
                    }/>
                    <FormControlLabel label={t('price.gift')} control={
                        <Checkbox checked={features.present} onChange={handleFeatureChange('present')} />
                    }/>
                    <FormControlLabel label={t('price.love')} control={
                        <Checkbox checked={features.loveStory} onChange={handleFeatureChange('loveStory')} />
                    }/>
                </div>
                <div className='price'>
                    <Typography className='contact'>{t('price.contact')}</Typography>
                    <Typography className='priceValue'>{price} CHF</Typography>
                </div>
            </div>
        </div>
    )
}